<div class="fx-layout-row-nowrap fx-content-start fx-fill">
  <div class="left-side fx-overflow-auto" style="width: 200px">
    <mat-nav-list>
      <div mat-subheader>{{ "PRINTING_TEMPLATES" | translate }}</div>
      @for (file of printingFiles; track $index) {
      <mat-list-item (click)="openFile(file)">
        <mat-icon
          class="word-icon-gradient"
          style="margin-right: 16px !important; color: #12b2ff"
          fontIcon="mdi-file-word-box"
          fontSet="mdi"
          matListItemIcon
        ></mat-icon>
        <div matListItemTitle>{{ file.name }}</div>
      </mat-list-item>
      }
    </mat-nav-list>
  </div>

  <ft-reporter
    (editorInitialized)="editorReady($event)"
    [documentTitle]="selectedFile?.name"
    [editable]="true"
    [fileId]="'P_' + selectedFile?.name"
    [templateMode]="1"
    [templateModel]="selectedFile?.name"
    class="fx-grow-1"
  >
  </ft-reporter>
</div>
