<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
  <mat-icon fontIcon="mdi-account-multiple" fontSet="mdi"></mat-icon>
  <h3>{{ "USERS" | translate }}</h3>

  <span class="fx-grow-1"></span>

  <button mat-button class="btn-top" (click)="editUser()">
    <mat-icon
      class="add-icon-gradient"
      fontIcon="mdi-plus"
      fontSet="mdi"
    ></mat-icon>
    {{ "ADD_NEW" | translate }}
  </button>
</mat-toolbar>

<div class="relative fx-layout-column-nowrap ft-setting-card-width">
  <div
    class="fx-search-area fx-layout-row-nowrap fx-gap-4 fx-padding-8 fx-content-start fx-items-center"
  >
    <mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
      <mat-icon
        matIconPrefix
        fontIcon="mdi-magnify"
        fontSet="mdi"
        tabindex="-1"
      ></mat-icon>
      <input
        matInput
        [placeholder]="'SEARCH' | translate"
        [formControl]="filter"
      />
    </mat-form-field>
  </div>

  <mat-table
    [dataSource]="dataSource"
    class="fx-grow-1 fx-overflow-auto"
    style="height: calc(100vh - 212px) !important; padding: 0 16px"
    matSort
    matSortDirection="asc"
    matSortActive="username"
  >
    <!--Table-->
    @for (col of displayedColumns; track $index) {
    <ng-container cdkColumnDef="{{ col.value }}" [class.hidden]="col.hidden">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ col.header | translate }}</mat-header-cell
      >

      @if (col.type === 'profile') {
      <mat-cell *cdkCellDef="let row">
        {{ profileName(row[col.value]) }}</mat-cell
      >
      } @if (col.type === 'function') {
      <mat-cell *cdkCellDef="let row">
        {{ functionName(row[col.value]) | translate }}</mat-cell
      >
      } @if (!['color', 'profile', 'function'].includes(col.type)) {
      <mat-cell *cdkCellDef="let row">
        {{ row[col.value] | translate }}</mat-cell
      >
      } @if (col.type === 'color') {
      <mat-cell *cdkCellDef="let row">
        <mat-icon
          [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + row[col.value] + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
          fontSet="mdi"
          fontIcon="mdi-checkbox-blank-circle"
        ></mat-icon>
      </mat-cell>
      }
    </ng-container>
    }

    <!--actions-->
    <ng-container cdkColumnDef="action">
      <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button
          mat-icon-button
          (click)="$event.stopPropagation(); editUser(row)"
        >
          <mat-icon
            class="edit-icon-gradient"
            fontIcon="mdi-pencil"
            fontSet="mdi"
          ></mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="$event.stopPropagation(); deleteUser(row)"
        >
          <mat-icon
            class="delete-icon-gradient"
            fontIcon="mdi-delete"
            fontSet="mdi"
          ></mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!--header-->
    <mat-header-row *cdkHeaderRowDef="cols; sticky: true"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: cols"> </mat-row>
  </mat-table>

  <mat-paginator
    class="fx-height-46"
    [length]="dataSource.data.length"
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
