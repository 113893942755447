import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SettingService } from "../setting.service";
import { DeleteConfirmComponent, SharedService } from "../../shared";
import { UserEditComponent } from "./user-edit/user-edit.component";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { union } from "lodash";
import { USER_TABLE_CONF } from "./table-conf";
import { MatTableDataSource } from "@angular/material/table";
import { UserAccountDTO } from "../../model";
import {
  catchError,
  debounceTime,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { merge, of as observableOf } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ft-users-setting",
  templateUrl: "./users-setting.component.html",
  styleUrls: ["./users-setting.component.scss"],
})
export class UsersSettingComponent implements OnInit, AfterViewInit {
  cols: any[];
  displayedColumns = [];

  private profiles: any[] = [];

  public dataSource = new MatTableDataSource<UserAccountDTO>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private settingService = inject(SettingService);
  private sharedService = inject(SharedService);
  private dialog = inject(MatDialog);

  public filter: FormControl = new FormControl<any>("");

  ngOnInit() {
    this.sharedService
      .getShortProfiles()
      .subscribe((data) => (this.profiles = data));

    this.displayedColumns = USER_TABLE_CONF;
    this.cols = union(
      USER_TABLE_CONF.filter((it) => !it.hidden).map((it) => it.value),
      ["action"]
    );
  }

  ngAfterViewInit() {
    this.sort?.sortChange.subscribe((_) => (this.paginator.pageIndex = 0));

    const observedFilters = [
      this.sort?.sortChange.asObservable(),
      this.paginator?.page.asObservable(),
      this.filter.valueChanges.pipe(debounceTime(250)),
    ];

    merge(...observedFilters)
      .pipe(
        startWith({}),
        switchMap(() => {
          const query = this.filter.getRawValue();

          return this.sharedService.getAllUsers(
            this.paginator.pageSize,
            this.paginator.pageIndex,
            this.sort.active,
            this.sort.direction,
            query
          );
        }),
        map((data) => data["content"] as UserAccountDTO[]),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  editUser(user: UserAccountDTO = new UserAccountDTO()) {
    const dialogRef = this.dialog.open(UserEditComponent);
    const instance = dialogRef.componentInstance;

    instance.selectedUser = user;

    dialogRef.afterClosed().subscribe((res) => {
      if (res) this.filter.patchValue("");
    });
  }

  deleteUser(user: UserAccountDTO) {
    this.dialog
      .open(DeleteConfirmComponent)
      .afterClosed()
      .subscribe((ok) => {
        if (ok) {
          this.settingService.deleteUser(user).subscribe((_) => {});
        }
      });
  }

  public profileName(id: number): string {
    return this.profiles.find((it) => it.id === id)?.name ?? "-";
  }

  public functionName(id: number): string {
    const funcions = {
      130: "REFERRING_PHYSICIAN",
      131: "PERFORMING_PHYSICIAN",
      132: "ADMINISTRATOR",
      133: "TECHNICIAN",
      134: "ASSISTANT",
    };
    return funcions[id] ?? "-";
  }
}
