@if (!uploadDone && uploading) {
<div class="upload-container">
  <div class="upload-progress">
    <mat-spinner [diameter]="20" [strokeWidth]="2" color="warn"></mat-spinner>
    {{ "UPLOADING" | translate }}...
  </div>
</div>
}

<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
  <mat-icon fontIcon="mdi-folder-move" fontSet="mdi"></mat-icon>
  <h3>{{ "PROCEDURECODES" | translate }}</h3>

  <span class="fx-grow-1"></span>

  <button (click)="uploadFile()" mat-button>
    <mat-icon
      class="upload-icon-gradient"
      fontIcon="mdi-upload"
      fontSet="mdi"
    ></mat-icon>
    {{ "UPLOAD_PROCEDURES" | translate }}
  </button>

  <button (click)="deleteAll()" mat-button>
    <mat-icon
      class="delete-icon-gradient"
      fontIcon="mdi-delete"
      fontSet="mdi"
    ></mat-icon>
    {{ "DELETE_ALL" | translate }}
  </button>

  <button (click)="exportProcedures()" mat-button>
    <mat-icon
      class="excel-icon-gradient"
      fontIcon="mdi-microsoft-excel"
      fontSet="mdi"
    ></mat-icon>
    {{ "EXPORT_TO_EXCEL" | translate }}
  </button>

  <button mat-button (click)="editProcedureCode(null)">
    <mat-icon
      class="add-icon-gradient"
      fontIcon="mdi-plus"
      fontSet="mdi"
    ></mat-icon>
    {{ "ADD_NEW" | translate }}
  </button>
</mat-toolbar>

<div
  class="fx-search-area fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4 fx-padding-8"
>
  <mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
    <mat-icon
      matIconPrefix
      fontIcon="mdi-magnify"
      fontSet="mdi"
      tabindex="-1"
    ></mat-icon>
    <input matInput #filter [placeholder]="'SEARCH' | translate" />
  </mat-form-field>

  @if (isLoadingResults || isRateLimitReached) {
  <mat-spinner
    mode="indeterminate"
    [strokeWidth]="2"
    [diameter]="20"
  ></mat-spinner>
  }
</div>

<div class="relative fx-layout-column-nowrap ft-setting-card-width">
  <mat-table
    [dataSource]="dataSource"
    class="fx-grow-1 fx-overflow-auto"
    style="height: calc(100vh - 208px) !important; padding: 0 16px"
    [trackBy]="trackById"
    matSort
    matSortActive="code"
    matSortDirection="asc"
    matSortDisableClear
  >
    <!--Table-->
    @for (col of displayedColumns; track $index) {
    <ng-container cdkColumnDef="{{ col.label }}" [class.hidden]="col.hidden">
      <mat-header-cell
        *cdkHeaderCellDef
        [disabled]="!col.sortable"
        mat-sort-header="{{ col.value }}"
      >
        {{ col.header | translate }}</mat-header-cell
      >

      <ng-container>
        <mat-cell *cdkCellDef="let row"> {{ row[col.value] }}</mat-cell>
      </ng-container>
    </ng-container>
    }

    <!--actions-->
    <ng-container cdkColumnDef="action">
      <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button
          mat-icon-button
          (click)="$event.stopPropagation(); editProcedureCode(row)"
        >
          <mat-icon
            class="edit-icon-gradient"
            fontIcon="mdi-pencil"
            fontSet="mdi"
          ></mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="$event.stopPropagation(); deleteProcedureCode(row)"
        >
          <mat-icon
            class="delete-icon-gradient"
            fontIcon="mdi-delete"
            fontSet="mdi"
          ></mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!--header-->
    <mat-header-row *cdkHeaderRowDef="cols; sticky: true"></mat-header-row>
    <mat-row [@rowsAnimation]="" *cdkRowDef="let row; columns: cols"> </mat-row>
  </mat-table>

  <mat-paginator
    [length]="resultsLength"
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>

@if (downloading) {
<div
  class="fx-layout-row fx-content-center fx-items-center"
  style="
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.4);
  "
>
  <div
    class="fx-layout-row fx-content-center fx-items-center fx-gap-12"
    style="
      height: 86px;
      width: 360px;
      border-radius: 8px;
      background: rgb(255 255 255);
      color: #585858;
    "
  >
    <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
    <h3>{{ "EXPORTING" | translate }}</h3>
  </div>
</div>
}
<input
  #fileInput
  (change)="onFileChange($event)"
  id="procedures-file"
  name="procedures-file"
  style="visibility: hidden; display: none"
  type="file"
/>
