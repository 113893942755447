<mat-card
  class="relative fx-fill fx-layout-row fx-content-start fx-items-center fx-gap-4px"
>
  <div class="setting-sidenav fx-fill-height fx-overflow-auto">
    <mat-nav-list>
      <div mat-subheader>{{ "SYSTEM" | translate }}</div>
      @for (item of systemItems; track $index) {
      <mat-list-item
        class="fx-height-32"
        routerLink="{{ item.route }}"
        routerLinkActive="active"
        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
        (click)="setHeaderTitle(item)"
      >
        <mat-icon
          style="margin-right: 16px !important"
          matListItemIcon
          fontIcon="{{ item.icon }}"
          fontSet="mdi"
          [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
        ></mat-icon>
        <div matListItemTitle>{{ item.title | translate }}</div>
      </mat-list-item>
      }

      <div mat-subheader>{{ "EXAM_SETTING" | translate }}</div>
      @for (item of procedureItems; track $index) {
      <mat-list-item
        class="fx-height-32"
        routerLink="{{ item.route }}"
        routerLinkActive="active"
        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
        (click)="setHeaderTitle(item)"
      >
        <mat-icon
          style="margin-right: 16px !important"
          matListItemIcon
          fontIcon="{{ item.icon }}"
          fontSet="mdi"
          [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
        ></mat-icon>
        <div matListItemTitle>{{ item.title | translate }}</div>
      </mat-list-item>
      }

      <div mat-subheader>{{ "NETWORK_SETTING" | translate }}</div>
      @for (item of peersInterfacing; track $index) {
      <mat-list-item
        class="fx-height-32"
        routerLink="{{ item.route }}"
        routerLinkActive="active"
        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
        (click)="setHeaderTitle(item)"
      >
        <mat-icon
          style="margin-right: 16px !important"
          matListItemIcon
          fontIcon="{{ item.icon }}"
          fontSet="mdi"
          [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
        ></mat-icon>
        <div matListItemTitle>{{ item.title | translate }}</div>
      </mat-list-item>
      } @if ( profile && (profile.manageUsers !== 'NONE' ||
      profile.manageProfile !== 'NONE') ) {
      <div mat-subheader>
        {{ "USER_AND_PROFILE" | translate }}
      </div>
      } @for (item of securityItems; track $index) {
      <mat-list-item
        class="fx-height-32"
        routerLink="{{ item.route }}"
        routerLinkActive="active"
        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
        (click)="setHeaderTitle(item)"
      >
        <mat-icon
          style="margin-right: 16px !important"
          matListItemIcon
          fontIcon="{{ item.icon }}"
          fontSet="mdi"
          [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
        ></mat-icon>
        <div matListItemTitle>{{ item.title | translate }}</div>
      </mat-list-item>
      }
    </mat-nav-list>
  </div>

  <div class="relative fx-fill fx-layout-column">
    <mat-card-content
      class="relative fx-overflow-hidden"
      style="
        box-sizing: border-box;
        padding: 0 !important;
        width: calc(100vw - 272px) !important;
        height: inherit;
      "
    >
      <router-outlet></router-outlet>
    </mat-card-content>
  </div>
</mat-card>
